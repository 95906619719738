import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout'

import microfrontendLayout from './microfrontend-layout.html'

const routes = constructRoutes(microfrontendLayout)
const applications = constructApplications({
  routes,
  loadApp({ name }: { name: string }) {
    return System.import(name)
  },
})
const layoutEngine = constructLayoutEngine({ routes, applications })

// TODO: Handle 404 with an application
// This is a temporary solution to add a link to the home page when the user is redirected to the 404 page
// if the user is logged in, the link will redirect to the home page, otherwise it will redirect to the login page
window.addEventListener('single-spa:routing-event', () => {
  const div = document.querySelector('#redirect-not-found')

  // Javascript code needs to by dynamically added to the page, script tags do not work in the layout.
  if (div) {
    const currentPortal = sessionStorage.getItem('portal')
    const token = localStorage.getItem('token')
    const aElement = document.createElement('a')
    if (currentPortal && token) {
      aElement.href = `/${currentPortal}`
      aElement.innerText = 'Go to Home'
    } else {
      aElement.href = '/login'
      aElement.innerText = 'Go to Login'
    }
    div.appendChild(aElement)
  }
})

applications.forEach(registerApplication)
layoutEngine.activate()
start()
